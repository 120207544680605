@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .leaderboard-table tbody td {
    @apply py-2 px-4 hover:bg-slate-100 focus:outline-none border-x-2 border-slate-100;
  }

  .leaderboard-table thead th {
  	@apply py-2 px-[4px] mx-2 bg-slate-200 border-l-2 border-r-2 border-slate-50 ; 
	 
  }

  .leaderboard-page select{
	@apply py-2 px-4 mx-[2px] my-2 rounded-md 
  }

  
 .primary-btn {
	@apply hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 h-8 rounded-3xl 
	
 }

 .primary-btn-padding {

	@apply hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-2 py-1 m-1 rounded-xl  
 }

}
