

.diagonal-div-tl-br {
	background: linear-gradient(to top right,
	rgba(0,0,0,0) 0%,
	rgba(0,0,0,0) calc(50% - 1.5px),
	rgba(0,0,0,1) 50%,
	rgba(0,0,0,0) calc(50% + 1.5px),
	rgba(0,0,0,0) 100%)
}

.diagonal-div-bl-tr {
	background: 
         linear-gradient(to top left,
             rgba(0,0,0,0) 0%,
             rgba(0,0,0,0) calc(50% - 1.5px),
             rgba(0,0,0,1) 50%,
             rgba(0,0,0,0) calc(50% + 1.5px),
             rgba(0,0,0,0) 100%)
}